import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import ProductsPage from './pages/ProductsPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import {AuthProvider} from './context/AuthContext';
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import FormEditor from "./components/FormEditor";
import ReportsPage from "./components/ReportsPage";
import PartialOrderPage from "./components/PartialOrderPage";
import SettingsPage from "./pages/SettingsPage";
import Dashboard from "./pages/DashboardPage";
import ProductForm from "./components/ProductForm";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {NotificationProvider} from "./context/NotificationContext";
import Notification from "./components/Notification";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import SpendsPage from "./pages/SpendsPage";
import SpendsForm from "./components/SpendsForm";

const theme = createTheme();

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <NotificationProvider>
                <AuthProvider>
                    <Router>
                        <Layout>
                            <Routes>
                                <Route
                                    path="/login"
                                    element={
                                        <PublicRoute>
                                            <LoginPage/>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/register"
                                    element={
                                        <PublicRoute>
                                            <RegisterPage/>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/products"
                                    element={
                                        <PrivateRoute>
                                            <ProductsPage/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/spends"
                                    element={
                                        <PrivateRoute>
                                            <SpendsPage/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/spends-form"
                                    element={
                                        <PrivateRoute>
                                            <SpendsForm/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/orders"
                                    element={
                                        <PrivateRoute>
                                            <ReportsPage/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/change-password"
                                    element={
                                        <PrivateRoute>
                                            <ChangePasswordPage/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/metrics"
                                    element={
                                        <PrivateRoute>
                                            <Dashboard/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/partials"
                                    element={
                                        <PrivateRoute>
                                            <PartialOrderPage/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/settings"
                                    element={
                                        <PrivateRoute>
                                            <SettingsPage/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/create-product"
                                    element={
                                        <PrivateRoute>
                                            <ProductForm/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/edit-product/:id"
                                    element={
                                        <PrivateRoute>
                                            <ProductForm/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/duplicate-product/:id"
                                    element={
                                        <PrivateRoute>
                                            <ProductForm/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/products/:productId/create-form"
                                    element={
                                        <PrivateRoute>
                                            <FormEditor/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/forms/:formId/:productId"
                                    element={
                                        <PrivateRoute>
                                            <FormEditor/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="*" element={<Navigate to="/metrics"/>}/>
                            </Routes>
                        </Layout>
                    </Router>
                </AuthProvider>
                <Notification />
            </NotificationProvider>
        </ThemeProvider>
    );
}

export default App;

import React, { useEffect, useState } from 'react'
import { getProducts, deleteProduct } from '../api/productApi'
import { useNavigate } from 'react-router-dom'
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Pagination,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNotification } from "../context/NotificationContext"
import FormPreview from './FormPreview'
import axiosInstance from '../api/axiosInstance'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import CopyButton from "./CopyButton";

const ProductList = () => {
    const [products, setProducts] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [loadingProductId, setLoadingProductId] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [productToDelete, setProductToDelete] = useState(null)
    const [previewProduct, setPreviewProduct] = useState(null)
    const navigate = useNavigate()
    const { addNotification } = useNotification()

    useEffect(() => {
        fetchProducts()
    }, [currentPage])

    const fetchProducts = async () => {
        const response = await getProducts(currentPage)
        setProducts(response.data)
        setCurrentPage(response.current_page)
        setLastPage(response.last_page)
    }

    const handleDelete = async () => {
        if (productToDelete) {
            const response = await deleteProduct(productToDelete.id)
            setProducts(products.filter(product => product.id !== productToDelete.id))
            handleClose()
            addNotification(response.message, 'success')
        }
    }

    const handlePageChange = (event, page) => {
        setCurrentPage(page)
    }

    const handleEdit = (id) => {
        navigate(`/edit-product/${id}`)
    }

    const handleDuplicate = (id) => {
        navigate(`/duplicate-product/${id}`)
    }

    const handleClickOpen = (product) => {
        setProductToDelete(product)
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
        setProductToDelete(null)
        setPreviewProduct(null)
    }

    const handleViewForm = async (productId) => {
        setLoadingProductId(productId)
        try {
            const response = await axiosInstance.get(`/products/${productId}/forms`)
            if (response.data.length > 0) {
                navigate(`/forms/${response.data[0].id}/${productId}`)
            } else {
                navigate(`/products/${productId}/create-form`)
            }
        } catch (error) {
            console.error('Error checking form existence:', error)
        } finally {
            setLoadingProductId(null)
        }
    }

    const handlePreviewForm = (product) => {
        if (product.form) {
            setPreviewProduct(product)
        } else {
            addNotification('This product does not have a form', 'warning')
        }
    }

    const getScriptShortCode = () => {
        if (!previewProduct) return

        return `<div id="form-container"></div>
            <script>
                (function(){
                    const t=\`v=\${Date.now()}\`,
                          e=document.createElement("script");
                    e.src=\`${process.env.REACT_APP_API_DOMAIN}/js/formGenerator.js?\${t}\`;
                    document.head.appendChild(e);
                    e.onload=function(){
                        loadForm('${previewProduct.slug}', 'form-container', '${process.env.REACT_APP_API_DOMAIN}', '${previewProduct.api_key}')
                    }
                })();
            </script>`
    }

    return (
        <Container maxWidth="xxl">
            <Typography variant="h4" gutterBottom>Products</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Slug (Unique)</TableCell>
                            <TableCell>Product ID</TableCell>
                            <TableCell>Options</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map(product => (
                            <TableRow key={product.id}>
                                <TableCell>{product.id}</TableCell>
                                <TableCell>{product.name}</TableCell>
                                <TableCell>{product.slug}</TableCell>
                                <TableCell>{product.product_id}</TableCell>
                                <TableCell>
                                    <ul>
                                        {product.options.map(option => (
                                            <li key={option.id}>
                                                {option.name} - {option.value} LEI{/* - {option.quantity}*/}
                                            </li>
                                        ))}
                                    </ul>
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(product.id)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleClickOpen(product)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDuplicate(product.id)}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleViewForm(product.id)}
                                                disabled={loadingProductId === product.id}
                                                startIcon={loadingProductId === product.id ? <CircularProgress size={24} /> : null}>
                                        <FormatAlignCenterIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handlePreviewForm(product)}>
                                        <RemoveRedEyeIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={lastPage}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
            />

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this product?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={Boolean(previewProduct)}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                aria-labelledby="form-preview-title"
                aria-describedby="form-preview-description"
            >
                <DialogTitle id="form-preview-title">Form Preview <CopyButton textToCopy={getScriptShortCode()} /></DialogTitle>
                <DialogContent>
                    {previewProduct && (
                        <FormPreview
                            productSlug={previewProduct.slug}
                            apiUrl={`${process.env.REACT_APP_API_DOMAIN}`}
                            apiKey={previewProduct.api_key}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default ProductList

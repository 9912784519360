import React, { useState, useEffect } from 'react'
import axiosInstance from '../api/axiosInstance'
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Box,
    Card,
    CardContent,
    Pagination,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    IconButton,
    Tooltip,
    Checkbox,
    Button, Chip, OutlinedInput, ListItemText
} from '@mui/material'
import dayjs from 'dayjs'
import { styled } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete'
import WarningIcon from '@mui/icons-material/Warning'
import ConfirmationDialog from '../components/ConfirmationDialog'
import CheckIcon from '@mui/icons-material/Check'
import AddIcCallIcon from '@mui/icons-material/AddIcCall'

const StyledTableContainer = styled(TableContainer)({
    marginTop: '20px',
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const PartialOrdersPage = () => {
    const [partialOrders, setPartialOrders] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalOrders, setTotalOrders] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [shouldSearch, setShouldSearch] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState('')
    const [selectedProductName, setSelectedProductName] = useState('All products')
    const [products, setProducts] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [orderToDelete, setOrderToDelete] = useState(null)
    const [selectedOrders, setSelectedOrders] = useState([])
    const [excludedProducts, setExcludedProducts] = useState([])
    const [chips, setChips] = useState([])

    useEffect(() => {
        fetchPartialOrders()
    }, [currentPage, shouldSearch, excludedProducts])

    const fetchPartialOrders = async () => {
        const response = await axiosInstance.get('/reports/partial-orders', {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: {
                page: searchTerm ? 1 : currentPage,
                searchTerm: searchTerm,
                product_id: selectedProduct,
                excluded_ids: excludedProducts,
            }
        })
        setPartialOrders(response.data.orders)
        setTotalPages(response.data.total_pages)
        setTotalOrders(response.data.order_count)
        setProducts(response.data.products)

        if (chips.length === 0) {
            setChips(response.data.products)
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value)
    }

    const handleSearchChange = (event) => {
        if (event.target.value === '') setShouldSearch((prev) => prev + 1)
        setSearchTerm(event.target.value)
    }

    const handleSearchDatabase = (event) => {
        if (event.key === 'Enter') {
            setShouldSearch((prev) => prev + 1)
        }
    }

    const handleSelectProduct = async (event) => {
        setSelectedProduct(event.target.value)
        setSelectedProductName(products.find(product => product.id === event.target.value)?.name)

        const response = await axiosInstance.get('/reports/partial-orders', {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: {
                page: currentPage,
                searchTerm: searchTerm,
                product_id: event.target.value,
            }
        })

        setPartialOrders(response.data.orders)
        setTotalPages(response.data.total_pages)
        setTotalOrders(response.data.order_count)
        setProducts(response.data.products)
    }

    const handleClickOpen = (order) => {
        setOrderToDelete(order)
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
        setOrderToDelete(null)
    }

    const handleConfirmDelete = async () => {
        if (orderToDelete) {
            try {
                await axiosInstance.delete(`/reports/partial-orders/${orderToDelete.id}`, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                })
                fetchPartialOrders()
                handleClose()
            } catch (error) {
                console.error('Error deleting partial:', error)
            }
        }
    }

    const handleSelectOrder = (orderId) => {
        setSelectedOrders((prevSelectedOrders) =>
            prevSelectedOrders.includes(orderId)
                ? prevSelectedOrders.filter((id) => id !== orderId)
                : [...prevSelectedOrders, orderId]
        )
    }

    const handleSelectAllOrders = (event) => {
        if (event.target.checked) {
            const allOrderIds = partialOrders.map((order) => order.id)
            setSelectedOrders(allOrderIds)
        } else {
            setSelectedOrders([])
        }
    }

    const handleDeleteSelected = async () => {
        try {
            await axiosInstance.post('/reports/partial-orders/bulk-delete', { orderIds: selectedOrders }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            })
            fetchPartialOrders()
            setSelectedOrders([])
        } catch (error) {
            console.error('Error deleting partials:', error)
        }
    }

    const handleAddCall = async order => {
        try {
            await axiosInstance.get(`/reports/partial-orders/add-call/${order.id}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            })
            fetchPartialOrders()
        } catch (error) {
            console.error('Error adding calls to partials:', error)
        }
    }

    const handleExclude = event => {
        const {target: { value }} = event

        setExcludedProducts(
            typeof value === 'string' ? value.split(',') : value,
        )
    }

    const getSelectedNames = (selectedIds) => {
        return selectedIds
            .map(id => chips.find(product => product.id === id))
            .filter(product => product)
            .map(product => product.name)
            .join(', ')
    }

    const buildPartialName = order => {
        let name = `${order.option?.name}`

        if (order.colors && order.colors.length > 0) {
            const colorList = order.colors.join(', ');
            name += ` (${colorList})`;
        }

        return <div dangerouslySetInnerHTML={{ __html: name }} />
    }

    return (
        <Container maxWidth="xxl">
            <Typography variant="h4" gutterBottom>Partiale</Typography>

            <Card>
                <CardContent>
                    <Box display="flex" alignItems="center">
                        <FormControl sx={{ m: 1, minWidth: 330 }}>
                            <InputLabel id="demo-simple-select-label">Produs</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedProduct}
                                label="Produs"
                                onChange={handleSelectProduct}
                            >
                                {products.map(product =>
                                    <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, width: 330 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Exclude partials</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={excludedProducts}
                                onChange={handleExclude}
                                input={<OutlinedInput label="Exclude partials" />}
                                renderValue={(selected) => getSelectedNames(selected)}
                                MenuProps={MenuProps}
                            >
                                {chips.map((product) => (
                                    <MenuItem key={product.name} value={product.id}>
                                        <Checkbox checked={excludedProducts.indexOf(product.id) > -1} />
                                        <ListItemText primary={product.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            value={searchTerm}
                            id="outlined-basic"
                            label="Cauta"
                            variant="outlined"
                            onChange={handleSearchChange}
                            onKeyDown={handleSearchDatabase}
                            type='search'
                        />
                    </Box>
                </CardContent>
            </Card>

            <StyledTableContainer component={Paper}>
                <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography style={{ marginLeft: 15 }} variant="h4" gutterBottom>{selectedProductName} - {totalOrders}</Typography>

                {selectedOrders.length > 0 && (
                    <Box>
                        <Button variant="contained" color="error" onClick={handleDeleteSelected} startIcon={<DeleteIcon />}>
                            Delete Selected
                        </Button>
                    </Box>
                )}
                </Box>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selectedOrders.length > 0 && selectedOrders.length < partialOrders.length}
                                    checked={partialOrders.length > 0 && selectedOrders.length === partialOrders.length}
                                    onChange={handleSelectAllOrders}
                                    inputProps={{ 'aria-label': 'select all orders' }}
                                />
                            </TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Created at</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>Option</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>County</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell align="right">Shipment</TableCell>
                            <TableCell align="center">Submit Attempt</TableCell>
                            <TableCell align="center">Phone Exists</TableCell>
                            <TableCell align="center">Number Of Calls</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partialOrders.map((order) => (
                            <TableRow key={order.id} selected={selectedOrders.includes(order.id)}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedOrders.includes(order.id)}
                                        onChange={() => handleSelectOrder(order.id)}
                                    />
                                </TableCell>
                                <TableCell>{order.id}</TableCell>
                                <TableCell align="left">{dayjs(order.created_at).format('DD MMMM YYYY HH:mm')}</TableCell>
                                <TableCell>{`${order.quantity} x ${order.product?.name} - ${order.price}`}</TableCell>
                                <TableCell>{buildPartialName(order)}</TableCell>
                                <TableCell>{order.name}</TableCell>
                                <TableCell>{order.phone}</TableCell>
                                <TableCell>{order.county}</TableCell>
                                <TableCell>{order.city}</TableCell>
                                <TableCell>{order.address}</TableCell>
                                <TableCell align="right">{order.shipping}</TableCell>
                                <TableCell align="center">{order.submitAttempt ?
                                    <Tooltip title="Attempt to press the submit button">
                                        <CheckIcon color="success" />
                                    </Tooltip> : <Tooltip title="Did not press the submit button">
                                        <WarningIcon color="error" />
                                    </Tooltip>}</TableCell>
                                <TableCell align="center">
                                    {order.phone_exists && (
                                        <Tooltip title="Phone number exists in orders">
                                            <CheckIcon color="success" />
                                        </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell align="center"><Chip label={order.no_of_calls} color="primary" size="small" /></TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleAddCall(order)} aria-label="delete">
                                        <Tooltip title="Add info that you called this partial">
                                            <AddIcCallIcon />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton onClick={() => handleClickOpen(order)} aria-label="delete">
                                        <Tooltip title="Delete partial">
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <Box display="flex" justifyContent="space-between" marginTop="20px">
                <Typography>Showing <strong>{totalOrders >= 15 ? 15 : totalOrders}</strong> from <strong>{totalOrders}</strong> orders</Typography>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>

            <ConfirmationDialog
                open={openDialog}
                handleClose={handleClose}
                handleConfirm={handleConfirmDelete}
                title="Delete confirmation"
                description="Are you sure you want to delete this?"
            />
        </Container>
    )
}

export default PartialOrdersPage
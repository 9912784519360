import React, { useEffect, useState } from 'react'
import { createProduct, duplicateProduct, showDuplicateProduct, showProduct, updateProduct } from '../api/productApi'
import { Container, Grid, TextField, Button, Typography, Card, CardContent, IconButton, Switch, FormControlLabel } from '@mui/material'
import { useNavigate, useParams, useLocation } from "react-router-dom"
import DeleteIcon from '@mui/icons-material/Delete'
import { useNotification } from "../context/NotificationContext"

const ProductForm = () => {
    const [name, setName] = useState('')
    const [slug, setSlug] = useState('')
    const [productId, setProductId] = useState('')
    const [options, setOptions] = useState([{ name: '', value: '', quantity: '', catch_phrase: '', image: null, imageUrl: '', reduced_price: '', colors: [{ color: '', woocommerce_product_id: '' }], productIds: [''], preselected: false }])
    const [variations, setVariations] = useState([{ size: '', color: '', image: null, imageUrl: '' }])
    const [removedImages, setRemovedImages] = useState([])
    const [formId, setFormId] = useState(null)
    const [shouldSyncWithWooCommerce, setShouldSyncWithWooCommerce] = useState(false)
    const [showData, setShowData] = useState(true)
    const { id } = useParams()
    const { addNotification, addErrorNotifications } = useNotification()
    const navigate = useNavigate()
    const location = useLocation()
    const isDuplicate = location.pathname.includes("duplicate")
    const [useAdvancedOptions, setUseAdvancedOptions] = useState(false)
    const [useOptionId, setUseOptionId] = useState(false)

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                let product
                if (isDuplicate) {
                    product = await showDuplicateProduct(id)
                    setFormId(product.form?.id)
                } else if (id) {
                    product = await showProduct(id)
                }

                if (product) {
                    if (!isDuplicate) {
                        setName(product.name)
                        setSlug(product.slug)
                        setProductId(product.product_id)
                    }
                    setShouldSyncWithWooCommerce(product.should_sync_with_woocommerce)
                    setShowData(product.show_data)
                    setUseAdvancedOptions(product.use_advanced_options)
                    setUseOptionId(product.use_option_id)

                    const processedOptions = product.options.map(option => ({
                        ...option,
                        imageUrl: option.image_url || '',
                        colors: option.colors ? option.colors.map(color => ({
                            id: color.id || '',
                            color: color.color || '',
                            woocommerce_product_id: color.woocommerce_product_id || ''
                        })) : [{ color: '', woocommerce_product_id: '' }],
                        productIds: option.metadata?.product_ids || [''],
                        preselected: option.metadata?.preselected
                    }))

                    if (processedOptions.some(option => Array.isArray(option.productIds) && option.productIds.length > 0 && option.productIds[0] !== '')) setUseOptionId(true)

                    setOptions(processedOptions)

                    setVariations(product.variations.map(variation => ({
                        ...variation,
                        imageUrl: variation.image_url
                    })))
                }
            } catch (error) {
                console.error("Error fetching product:", error)
            }
        }

        fetchProduct()

    }, [id, location.pathname, isDuplicate])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const filteredVariations = variations.filter(variation => {
            return variation.size || variation.color || variation.image
        }).map(variation => {
            const filteredVariation = { ...variation }
            if (!filteredVariation.size) {
                delete filteredVariation.size
            }
            if (!filteredVariation.color) {
                delete filteredVariation.color
            }
            return filteredVariation
        })

        const filteredOptions = options.filter(option => {
            return option.name || option.value || option.quantity || option.catch_phrase || option.image
        }).map(option => {
            const filteredOption = { ...option }
            if (!filteredOption.catch_phrase) {
                delete filteredOption.catch_phrase
            }
            if (!filteredOption.reduced_price) {
                delete filteredOption.reduced_price
            }
            if (useOptionId) {
                filteredOption.productIds = option.productIds.filter(pid => pid)
            }
            if (useAdvancedOptions) {
                filteredOption.colors = filteredOption.colors.filter(color => {
                    return color.color && color.woocommerce_product_id
                }).map(color => {
                    const filteredColor = { ...color }
                    if (!filteredColor.id) {
                        delete filteredColor.id
                    }
                    return filteredColor
                })
            }
            return filteredOption
        })

        const productData = {
            name,
            product_id: productId,
            options: filteredOptions,
            variations: filteredVariations,
            removedImages,
            should_sync_with_woocommerce: shouldSyncWithWooCommerce,
            use_advanced_options: useAdvancedOptions,
            show_data: showData,
            use_option_id: useOptionId
        }

        if (isDuplicate) {
            try {
                await duplicateProduct(id, { ...productData, form_id: formId })
                addNotification('Product duplicated successfully', 'success')
                navigate('/products')
            } catch (e) {
                addErrorNotifications(e.response?.data)
            }
            return
        }

        if (id) {
            try {
                await updateProduct(id, productData)
                addNotification('Product updated successfully', 'success')
                navigate('/products')
            } catch (e) {
                addErrorNotifications(e.response?.data)
            }
        } else {
            try {
                await createProduct(productData)
                addNotification('Product created successfully', 'success')
                navigate('/products')
            } catch (e) {
                addErrorNotifications(e.response?.data)
            }
        }
    }

    const handleOptionChange = (index, field, value) => {
        const newOptions = [...options]
        newOptions[index][field] = value
        if (field === 'quantity' && useOptionId) {
            const quantity = parseInt(value, 10)
            newOptions[index].productIds = new Array(quantity || 1).fill('')
        }
        setOptions(newOptions)
    }

    const handleColorChange = (optionIndex, colorIndex, field, value) => {
        const newOptions = [...options]
        newOptions[optionIndex].colors[colorIndex][field] = value
        setOptions(newOptions)
    }

    const handleVariationChange = (index, field, value) => {
        const newVariations = [...variations]
        newVariations[index][field] = value
        setVariations(newVariations)
    }

    const handleImageChange = (index, type, event) => {
        const file = event.target.files[0]
        if (type === 'option') {
            const newOptions = [...options]
            if (file) {
                newOptions[index].image = file
                newOptions[index].imageUrl = URL.createObjectURL(file)
            } else {
                setRemovedImages([...removedImages, newOptions[index].image])
                newOptions[index].image = null
                newOptions[index].imageUrl = ''
            }
            setOptions(newOptions)
        } else if (type === 'variation') {
            const newVariations = [...variations]
            if (file) {
                newVariations[index].image = file
                newVariations[index].imageUrl = URL.createObjectURL(file)
            } else {
                setRemovedImages([...removedImages, newVariations[index].image])
                newVariations[index].image = null
                newVariations[index].imageUrl = ''
            }
            setVariations(newVariations)
        }
    }

    const addOption = () => {
        setOptions([...options, { name: '', value: '', quantity: '', catch_phrase: '', image: null, imageUrl: '', reduced_price: '', productIds: [''], preselected: false }])
    }

    const addVariation = () => {
        setVariations([...variations, { size: '', color: '', image: null, imageUrl: '' }])
    }

    const removeOption = (index) => {
        const newOptions = options.filter((_, i) => i !== index)
        setOptions(newOptions)
    }

    const removeVariation = (index) => {
        const newVariations = variations.filter((_, i) => i !== index)
        if (variations[index].image) {
            setRemovedImages([...removedImages, variations[index].image])
        }
        setVariations(newVariations)
    }

    const handleFocus = (index, field) => {
        const newVariations = variations.map((variation, i) => {
            if (i === index) {
                return {
                    ...variation,
                    disableSize: field === 'color',
                    disableColor: field === 'size'
                }
            }
            return variation
        })
        setVariations(newVariations)
    }

    const handleBlur = (index, field) => {
        const newVariations = variations.map((variation, i) => {
            if (i === index && !variation[field]) {
                return {
                    ...variation,
                    disableSize: false,
                    disableColor: false
                }
            }
            return variation
        })
        setVariations(newVariations)
    }

    const addColor = (optionIndex) => {
        const newOptions = [...options]
        newOptions[optionIndex].colors.push({ color: '', woocommerce_product_id: '' })
        setOptions(newOptions)
    }

    const removeColor = (optionIndex, colorIndex) => {
        const newOptions = [...options]
        newOptions[optionIndex].colors = newOptions[optionIndex].colors.filter((_, i) => i !== colorIndex)
        setOptions(newOptions)
    }

    const handleProductIdChange = (optionIndex, productIndex, value) => {
        const newOptions = [...options]
        newOptions[optionIndex].productIds[productIndex] = value
        setOptions(newOptions)
    }

    return (
        <Container maxWidth="xxl">
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>Product Form</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Slug"
                            value={slug}
                            fullWidth
                            margin="normal"
                            disabled
                        />
                        <TextField
                            label="Product ID"
                            value={productId}
                            onChange={(e) => setProductId(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={shouldSyncWithWooCommerce}
                                    onChange={(e) => setShouldSyncWithWooCommerce(e.target.checked)}
                                    name="shouldSyncWithWooCommerce"
                                    color="primary"
                                />
                            }
                            label="Sync with WooCommerce"
                            style={{ margin: '20px 0' }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showData}
                                    onChange={(e) => setShowData(e.target.checked)}
                                    name="showData"
                                    color="primary"
                                />
                            }
                            label="Show Orders"
                            style={{ margin: '20px 20px' }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={useAdvancedOptions}
                                    onChange={(e) => setUseAdvancedOptions(e.target.checked)}
                                    name="useAdvancedOptions"
                                    color="primary"
                                />
                            }
                            label="Use Advanced Options"
                            style={{ margin: '20px 0' }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={useOptionId}
                                    onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        setUseOptionId(isChecked);

                                        if (isChecked) {
                                            setOptions(options.map((option) => {
                                                const quantity = option.quantity ? parseInt(option.quantity, 10) : 1;
                                                const productIds = Array.isArray(option.productIds) ? option.productIds : [];

                                                return {
                                                    ...option,
                                                    productIds: productIds.length === quantity
                                                        ? productIds
                                                        : new Array(quantity).fill('').map((_, index) => productIds[index] || ''),
                                                };
                                            }));
                                        }
                                    }}
                                    name="useOptionId"
                                    color="primary"
                                />
                            }
                            label="Use Option ID"
                            style={{ margin: '20px 0' }}
                        />
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Options</Typography>
                                {options.map((option, index) => (
                                    <Card key={index} style={{ marginBottom: '20px', padding: '20px' }}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Option {index + 1}
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={option.preselected}
                                                        onChange={(e) => handleOptionChange(index, 'preselected', e.target.checked)}
                                                        name="preselected"
                                                        color="primary"
                                                    />
                                                }
                                                label="Preselected"
                                            />
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Name"
                                                    value={option.name}
                                                    onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Value"
                                                    value={option.value}
                                                    onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Quantity"
                                                    value={option.quantity}
                                                    onChange={(e) => handleOptionChange(index, 'quantity', e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Catch Phrase"
                                                    value={option.catch_phrase}
                                                    onChange={(e) => handleOptionChange(index, 'catch_phrase', e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Reduced Price"
                                                    value={option.reduced_price}
                                                    onChange={(e) => handleOptionChange(index, 'reduced_price', e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            {useOptionId && option.productIds.map((productId, productIndex) => (
                                                <Grid item xs={4}>
                                                    <TextField
                                                        key={productIndex}
                                                        label={`Product ID ${productIndex + 1}`}
                                                        value={productId}
                                                        onChange={(e) => handleProductIdChange(index, productIndex, e.target.value)}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            ))}
                                            <Grid item xs={2}>
                                                <input
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id={`option-image-${index}`}
                                                    type="file"
                                                    onChange={(e) => handleImageChange(index, 'option', e)}
                                                />
                                                <label htmlFor={`option-image-${index}`}>
                                                    <Button variant="contained" component="span" style={{ marginTop: '16px' }}>
                                                        Upload Image
                                                    </Button>
                                                </label>
                                                {option.imageUrl && (
                                                    <div style={{ position: 'relative', display: 'inline-block', marginTop: '10px' }}>
                                                        <img src={option.imageUrl} alt="option" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                        <IconButton
                                                            onClick={() => handleImageChange(index, 'option', { target: { files: [null] } })}
                                                            style={{ position: 'absolute', top: '0', right: '0' }}
                                                        >
                                                            <DeleteIcon style={{ color: 'red' }} />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton onClick={() => removeOption(index)} aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>

                                            {useAdvancedOptions ? option.colors?.map((color, colorIndex) => (
                                                <Grid container spacing={2} key={colorIndex} style={{ marginLeft: 1 }}>
                                                    <Grid item xs={4}>
                                                        {color.id && (
                                                            <input
                                                                type="hidden"
                                                                value={color.id}
                                                                onChange={(e) => handleColorChange(index, colorIndex, 'id', e.target.value)}
                                                            />
                                                        )}
                                                        <TextField
                                                            label="Color"
                                                            value={color.color}
                                                            onChange={(e) => handleColorChange(index, colorIndex, 'color', e.target.value)}
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            label="WooCommerce Product ID"
                                                            value={color.woocommerce_product_id}
                                                            onChange={(e) => handleColorChange(index, colorIndex, 'woocommerce_product_id', e.target.value)}
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => removeColor(index, colorIndex)} aria-label="delete">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            )) : null}

                                            {useAdvancedOptions ? (
                                                <Button variant="contained" color="primary" onClick={() => addColor(index)} style={{ marginTop: '10px', marginLeft: '20px' }}>
                                                    Add Color
                                                </Button>
                                            ) : null}
                                        </Grid>
                                    </Card>
                                ))}
                                <Button variant="contained" color="primary" onClick={addOption} style={{ marginTop: '10px' }}>
                                    Add Option
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Variations</Typography>
                                {variations.map((variation, index) => (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Size"
                                                value={variation.size}
                                                onFocus={() => handleFocus(index, 'size')}
                                                onBlur={() => handleBlur(index, 'size')}
                                                onChange={(e) => handleVariationChange(index, 'size', e.target.value)}
                                                fullWidth
                                                margin="normal"
                                                disabled={variation.disableSize}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Color"
                                                value={variation.color}
                                                onFocus={() => handleFocus(index, 'color')}
                                                onBlur={() => handleBlur(index, 'color')}
                                                onChange={(e) => handleVariationChange(index, 'color', e.target.value)}
                                                fullWidth
                                                margin="normal"
                                                disabled={variation.disableColor}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id={`variation-image-${index}`}
                                                type="file"
                                                onChange={(e) => handleImageChange(index, 'variation', e)}
                                            />
                                            <label htmlFor={`variation-image-${index}`}>
                                                <Button variant="contained" component="span" style={{ marginTop: '16px' }}>
                                                    Upload Image
                                                </Button>
                                            </label>
                                            {variation.imageUrl && (
                                                <div style={{ position: 'relative', display: 'inline-block', marginTop: '10px' }}>
                                                    <img src={variation.imageUrl} alt="variation" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                    <IconButton
                                                        onClick={() => handleImageChange(index, 'variation', { target: { files: [null] } })}
                                                        style={{ position: 'absolute', top: '0', right: '0' }}
                                                    >
                                                        <DeleteIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => removeVariation(index)} aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Button variant="contained" color="primary" onClick={addVariation} style={{ marginTop: '10px' }}>
                                    Add Variation
                                </Button>
                            </Grid>
                        </Grid>
                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                            Save Product
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}

export default ProductForm

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PublicRoute = ({ children }) => {
    const { isAuthenticated, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>; // You can replace this with a better loading indicator
    }

    if (isAuthenticated) {
        return <Navigate to="/metrics" state={{ from: location }} />;
    }

    return children;
};

export default PublicRoute;

import React, { useEffect, useRef } from 'react'

const FormPreview = ({ productSlug, apiUrl, apiKey }) => {
    const iframeRef = useRef(null)

    useEffect(() => {
        const iframe = iframeRef.current
        if (!iframe) return

        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document

        const injectScript = (src, callback) => {
            const script = iframeDocument.createElement('script')
            script.type = 'text/javascript'
            script.src = src
            script.onload = callback
            iframeDocument.head.appendChild(script)
        }

        injectScript(`${apiUrl}/js/formGenerator.js`, () => {
            const script = iframeDocument.createElement('script')
            script.type = 'text/javascript'
            script.innerHTML = `
                loadForm('${productSlug}', 'form-container', '${apiUrl}', '${apiKey}')
            `
            iframeDocument.body.appendChild(script)

            const formContainer = iframeDocument.createElement('div')
            formContainer.id = 'form-container'
            iframeDocument.body.appendChild(formContainer)
        })
    }, [productSlug, apiUrl, apiKey])

    return (
        <iframe
            ref={iframeRef}
            style={{ width: '100%', height: '500px', border: 'none' }}
            title="Form Preview"
        />
    )
}

export default FormPreview
import React, { useState, useEffect } from 'react'
import { TextField, Button, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import axiosInstance from "../api/axiosInstance"
import { useNotification } from "../context/NotificationContext"
import { useLocation, useNavigate } from 'react-router-dom'

const SpendForm = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const initialState = location.state || { product_id: '', date: '', spend: '' }
    const [spend, setSpend] = useState(initialState)
    const [products, setProducts] = useState([])
    const { addNotification, addErrorNotifications } = useNotification()

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get('/products/dictionary')
                setProducts(response.data)
            } catch (error) {
                addErrorNotifications(['Failed to load products.'])
            }
        }
        fetchProducts()

        if (location.state) {
            setSpend(location.state)
        }
    }, [location.state])

    const handleChange = (e) => {
        setSpend({ ...spend, [e.target.name]: e.target.value })
    }

    const submitSpend = async () => {
        if (location.state) {
            try {
                await axiosInstance.post(`/spends/${location.state.product_id}`, spend)
                addNotification('Spend saved successfully', 'success')
                navigate(-1)
            } catch (error) {
                addErrorNotifications(error.response.data)
            }
        } else {
            addErrorNotifications({})
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Product</InputLabel>
                    <Select
                        label="Product"
                        name="product_id"
                        value={spend.product_id}
                        onChange={handleChange}
                        fullWidth
                        disabled={!!location.state}
                    >
                        {products.map((product) => (
                            <MenuItem key={product.id} value={product.id}>
                                {product.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="date"
                    label="Date"
                    type="date"
                    value={spend.date}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    disabled={!!location.state}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="spend"
                    label="Spend"
                    type="number"
                    value={spend.spend}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={submitSpend}>
                    {location.state ? 'Update Spend' : 'Submit Spend'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default SpendForm
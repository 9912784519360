import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProductList from '../components/ProductList';
import './css/ProductsPage.css';

const ProductsPage = () => {
    const navigate = useNavigate();

    const handleCreateNewProduct = () => {
        navigate('/create-product');
    };

    return (
        <div className="products-page">
            <h1>Products Management</h1>
            <button className="create-button" onClick={handleCreateNewProduct}>
                Create New Product
            </button>
            <ProductList />
        </div>
    );
};

export default ProductsPage;

import React, {useState, useEffect} from 'react'
import axiosInstance from '../api/axiosInstance'
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Box,
    Grid,
    Card,
    CardContent,
    Pagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Badge,
    IconButton,
    Checkbox,
    Button,
    Modal,
    Chip,
    Tooltip,
} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {styled} from '@mui/system'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import DeleteIcon from '@mui/icons-material/Delete'
import SyncIcon from '@mui/icons-material/Sync'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {useNotification} from '../context/NotificationContext'
import ConfirmationDialog from './ConfirmationDialog'
import BlockIcon from '@mui/icons-material/Block';
import WarningIcon from "@mui/icons-material/Warning";

const StyledTableContainer = styled(TableContainer)({
    marginTop: '20px',
})

const CustomBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'lightgreen',
        color: 'white',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const DangerBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const UpsellModal = ({open, handleClose, upsellDetails, upsellAmount}) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" component="h2">
                    Upsell Details
                </Typography>
                {upsellDetails && upsellDetails.length > 0 && (
                    <Box sx={{mt: 2}}>
                        <Typography variant="body1">
                            <strong>Product Name:</strong> {upsellDetails[0].name}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Upsell Amount:</strong> {upsellAmount} RON
                        </Typography>
                        {upsellDetails[0].options && upsellDetails[0].options.length > 0 && (
                            <Typography variant="body1">
                                <strong>Quantity:</strong> {upsellDetails[0].options[0].quantity}
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>
        </Modal>
    )
}

const ReportsPage = () => {
    const [ordersByDate, setOrdersByDate] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalOrders, setTotalOrders] = useState(0)
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [shouldSearch, setShouldSearch] = useState(0)
    const [usage, setUsage] = useState([])
    const [blackList, setBlackList] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [orderToDelete, setOrderToDelete] = useState(null)
    const [selectedOrders, setSelectedOrders] = useState([])
    const [openUpsellModal, setOpenUpsellModal] = useState(false)
    const [upsellDetails, setUpsellDetails] = useState([])
    const [upsellAmount, setUpsellAmount] = useState('')
    const {addNotification, addErrorNotifications} = useNotification()

    useEffect(() => {
        fetchOrdersByDate()
    }, [currentPage])

    useEffect(() => {
        fetchOrdersByDate(1)
    }, [startDate, endDate])

    useEffect(() => {
        if (!shouldSearch) return

        fetchOrdersByDate(1)
    }, [shouldSearch])

    const fetchOrdersByDate = async (page = currentPage) => {
        const response = await axiosInstance.get('/reports/orders-by-date', {
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
            params: {
                start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
                end_date: endDate ? endDate.format('YYYY-MM-DD') : null,
                page: page,
                product_id: selectedProduct,
                searchTerm: searchTerm,
            },
        })
        setOrdersByDate(response.data.orders)
        setTotalPages(response.data.total_pages)
        setProducts(response.data.products)
        setTotalOrders(response.data.order_count)

        await fetchInfoOrders(response.data.orders)
    }

    const fetchInfoOrders = async (orders) => {
        const phones = orders.map((order) => order.phone)

        await axiosInstance
            .post('/reports/get-info', {phones: phones})
            .then((response) => {
                setUsage(response.data.usage)
                setBlackList(response.data.blacklist)
            })
            .catch((error) => {
                console.error('Error fetching phone usage:', error)
            })
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value)
    }

    const handleSelectProduct = async (event) => {
        const value = event.target.value === 'all' ? '' : event.target.value
        setSelectedProduct(value)

        const response = await axiosInstance.get('/reports/orders-by-date', {
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
            params: {
                start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
                end_date: endDate ? endDate.format('YYYY-MM-DD') : null,
                page: 1,
                product_id: value,
                searchTerm: searchTerm,
            },
        })

        setOrdersByDate(response.data.orders)
        setTotalPages(response.data.total_pages)
        setProducts(response.data.products)
        setTotalOrders(response.data.order_count)

        await fetchInfoOrders(response.data.orders)
    }

    const handleChangeStartDate = (newValue) => {
        setStartDate(newValue)
        setCurrentPage(1)
    }

    const handleChangeEndDate = (newValue) => {
        setEndDate(newValue)
        setCurrentPage(1)
    }

    const handleSearchChange = (event) => {
        if (event.target.value === '') setShouldSearch((prev) => prev + 1)

        setSearchTerm(event.target.value)
    }

    const handleSearchDatabase = (event) => {
        if (event.key === 'Enter') {
            setShouldSearch((prev) => prev + 1)
        }
    }

    const getOrderCountByPhone = (phone) => {
        const phoneUsageEntry = usage.find((entry) => entry.normalized_phone === phone.replace(/\s+/g, '').slice(-10))
        return phoneUsageEntry ? phoneUsageEntry.order_count : 0
    }

    const getOrderBlackListed = (id) => {
        const blacklisted = blackList.find((entry) => entry.order_id === id)
        return !!blacklisted
    }

    const handleBlock = async order => {
        await axiosInstance.post('/reports/block-ip', {ip: order.ip_address})
            .then(() => addNotification(`${order.ip_address} has been blocked`, 'success'))
    }

    const handleClickOpen = (order) => {
        setOrderToDelete(order)
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
        setOrderToDelete(null)
    }

    const handleConfirmDelete = async () => {
        if (orderToDelete) {
            try {
                await axiosInstance.delete(`/reports/orders/${orderToDelete.id}`, {
                    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
                })

                const response = await axiosInstance.get('/reports/orders-by-date', {
                    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
                    params: {
                        start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
                        end_date: endDate ? endDate.format('YYYY-MM-DD') : null,
                        page: 1,
                        product_id: selectedProduct,
                        searchTerm: searchTerm,
                    },
                })

                setOrdersByDate(response.data.orders)
                setTotalPages(response.data.total_pages)
                setProducts(response.data.products)
                setTotalOrders(response.data.order_count)

                await fetchInfoOrders(response.data.orders)
                handleClose()
                addNotification('Order deleted successfully', 'success')
            } catch (error) {
                console.error('Error deleting order:', error)
                addErrorNotifications(error.response?.data)
            }
        }
    }

    const handleSelectOrder = (orderId) => {
        setSelectedOrders((prevSelected) =>
            prevSelected.includes(orderId)
                ? prevSelected.filter((id) => id !== orderId)
                : [...prevSelected, orderId]
        )
    }

    const handleDeleteSelected = async () => {
        try {
            await axiosInstance.post(
                '/reports/orders/batch-delete',
                {ids: selectedOrders},
                {
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                }
            )

            fetchOrdersByDate()
            setSelectedOrders([])
        } catch (error) {
            console.error('Error deleting orders:', error)
        }
    }

    const handleSyncSelected = async () => {
        try {
            await axiosInstance.post(
                '/reports/orders/batch-sync',
                {ids: selectedOrders},
                {
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                }
            )

            fetchOrdersByDate()
            setSelectedOrders([])
        } catch (error) {
            console.error('Error syncing orders:', error)
        }
    }

    const handleUpsellModalOpen = (upsellDetails, upsellAmount) => {
        setUpsellDetails(upsellDetails)
        setUpsellAmount(upsellAmount)
        setOpenUpsellModal(true)
    }

    const handleUpsellModalClose = () => {
        setOpenUpsellModal(false)
        setUpsellDetails([])
        setUpsellAmount('')
    }

    const buildOrderName = (order) => {
        let name = `${order.option_name}`
        const metadata = JSON.parse(order.metadata)

        if (metadata) {
            Object.values(metadata).forEach(meta => name = name + `<br />${meta.quantity} - ${meta.color}`)
        }

        return <div dangerouslySetInnerHTML={{ __html: name }} />
    }

    return (
        <Container maxWidth="xxl">
            <Typography variant="h4" gutterBottom>
                Comenzi
            </Typography>

            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Filters
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{m: 1}}
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => handleChangeStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} margin="normal"/>}
                            />
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue) => handleChangeEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} margin="normal"/>}
                            />
                        </LocalizationProvider>
                        <FormControl sx={{m: 1, minWidth: 330}}>
                            <InputLabel id="demo-simple-select-label">Produs</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedProduct}
                                label="Produs"
                                onChange={handleSelectProduct}
                            >
                                <MenuItem value="all">All Products</MenuItem>
                                {products.map((product) => (
                                    <MenuItem key={product.id} value={product.id}>
                                        {product.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            value={searchTerm}
                            id="outlined-basic"
                            label="Cauta"
                            variant="outlined"
                            onChange={handleSearchChange}
                            onKeyDown={handleSearchDatabase}
                            type="search"
                        />
                    </Box>
                </CardContent>
            </Card>

            <Grid container spacing={3} style={{marginTop: '20px'}}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography variant="h5" gutterBottom>
                                    Orders - {totalOrders}
                                </Typography>

                                {selectedOrders.length > 0 && (
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<DeleteIcon/>}
                                            onClick={handleDeleteSelected}
                                            style={{marginRight: '10px'}}
                                        >
                                            Delete Selected
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<SyncIcon/>}
                                            onClick={handleSyncSelected}
                                        >
                                            Sync with WooCommerce
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            <StyledTableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(event) =>
                                                        setSelectedOrders(
                                                            event.target.checked
                                                                ? ordersByDate.map((order) => order.id)
                                                                : []
                                                        )
                                                    }
                                                    checked={
                                                        selectedOrders.length === ordersByDate.length
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">Product</TableCell>
                                            <TableCell align="center">Option</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Phone</TableCell>
                                            <TableCell align="center">County</TableCell>
                                            <TableCell align="center">City</TableCell>
                                            <TableCell align="center">Address</TableCell>
                                            <TableCell align="center">Variatie</TableCell>
                                            <TableCell align="center">Total</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Woo Synced</TableCell>
                                            <TableCell align="right">Actiuni</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ordersByDate.map((order) => (
                                            <TableRow key={order.id}
                                                      style={{backgroundColor: getOrderBlackListed(order.id) ? '#FFCDD2' : 'inherit'}}>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={selectedOrders.includes(order.id)}
                                                        onChange={() => handleSelectOrder(order.id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">{order.id}</TableCell>
                                                <TableCell
                                                    align="center">{`${order.quantity} x ${order.product?.name}`}</TableCell>
                                                <TableCell align="center">{buildOrderName(order)}</TableCell>
                                                <TableCell align="center">
                                                    {order.blocked_ip ? <Tooltip title="This IP has been blocked">
                                                        <WarningIcon color="error" />
                                                    </Tooltip> : ''}
                                                    <Tooltip
                                                        title={`IP Address: ${order.ip_address ? order.ip_address : '-'}`}>
                                                        <span>{order.name}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <span style={{marginRight: 15}}>{order.phone}</span>{' '}
                                                    <span>
                                                        {getOrderCountByPhone(order.phone) > 1 ? (
                                                            <Badge
                                                                badgeContent={getOrderCountByPhone(
                                                                    order.phone
                                                                )}
                                                                color="primary"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="center">{order.county}</TableCell>
                                                <TableCell align="center">{order.city}</TableCell>
                                                <TableCell align="center">{order.address}</TableCell>
                                                <TableCell align="center">
                                                    {order.variation_name ? order.variation_name : '-'}
                                                </TableCell>
                                                <TableCell align="center"><Chip label={order.total} color="primary"
                                                                                size="small"/></TableCell>
                                                <TableCell align="center"><Chip label={order.status}
                                                                                color={order.status === 'completed' ? 'success' : 'warning'}
                                                                                size="small"/></TableCell>
                                                <TableCell align="center">
                                                    {dayjs(order.created_at).format('DD MMMM YYYY HH:mm')}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {order.synced ? (
                                                        <CustomBadge
                                                            badgeContent={<CheckIcon/>}
                                                            color="primary"
                                                        />
                                                    ) : (
                                                        <DangerBadge
                                                            badgeContent={<ErrorIcon/>}
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {order.upsell_amount > 0 && (
                                                        <IconButton
                                                            onClick={() => handleUpsellModalOpen(order.upsell_details, order.upsell_amount)}
                                                            aria-label="view upsell"
                                                        >
                                                            <VisibilityIcon/>
                                                        </IconButton>
                                                    )}
                                                    <Tooltip
                                                        title={`Block ip address`}>
                                                        <IconButton
                                                            onClick={() => handleBlock(order)}
                                                            aria-label="block"
                                                        >
                                                            <BlockIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={`Delete order`}>
                                                        <IconButton
                                                            onClick={() => handleClickOpen(order)}
                                                            aria-label="delete"
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </StyledTableContainer>
                            <Box display="flex" justifyContent="space-between" marginTop="20px">
                                <Typography>Showing <strong>{totalOrders >= 15 ? 15 : totalOrders}</strong> from <strong>{totalOrders}</strong> orders</Typography>
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <UpsellModal
                open={openUpsellModal}
                handleClose={handleUpsellModalClose}
                upsellDetails={upsellDetails}
                upsellAmount={upsellAmount}
            />

            <ConfirmationDialog
                open={openDialog}
                handleClose={handleClose}
                handleConfirm={handleConfirmDelete}
                title="Delete confirmation"
                description="Are you sure you want to delete this?"
            />
        </Container>
    )
}

export default ReportsPage
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNotification } from '../context/NotificationContext';

const Notification = () => {
    const { notifications, removeNotification } = useNotification();

    return (
        <>
            {notifications.map((notification) => (
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    key={notification.key}
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => removeNotification(notification.key)}
                >
                    <Alert onClose={() => removeNotification(notification.key)} severity={notification.severity}>
                        {notification.message}
                    </Alert>
                </Snackbar>
            ))}
        </>
    );
};

export default Notification;

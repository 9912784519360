import {useAuth} from "../context/AuthContext";
import {Navigate, useLocation} from "react-router-dom";
import React from "react";

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>; // You can replace this with a better loading indicator
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
};

export default PrivateRoute
import React, { useState, useEffect } from 'react'
import axiosInstance from "../api/axiosInstance"
import { formatNumber } from "../utils/Utils"
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    TextField, MenuItem, FormControl, InputLabel, Select,
} from '@mui/material'
import { styled } from '@mui/system'
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { formatInTimeZone } from 'date-fns-tz'
import dayjs from "dayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

const StyledContainer = styled(Container)({
    marginTop: '20px',
})

const CenteredCircularProgress = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
})

const Dashboard = () => {
    const [loading, setLoading] = useState(true)
    const [metrics, setMetrics] = useState({})
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [selectedRange, setSelectedRange] = useState('Today')
    const timeZone = 'Europe/Bucharest'

    useEffect(() => {
        updateDateRange(selectedRange)
    }, [])

    useEffect(() => {
        fetchMetrics(startDate, endDate)
    }, [startDate, endDate])

    const fetchMetrics = async (start, end) => {
        setLoading(true)
        try {
            const formattedStartDate = start ? formatInTimeZone(start.toDate(), timeZone, 'yyyy-MM-dd') : undefined
            const formattedEndDate = end ? formatInTimeZone(end.toDate(), timeZone, 'yyyy-MM-dd') : undefined
            const response = await axiosInstance.get('/reports/metrics', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: {
                    start_date: formattedStartDate,
                    end_date: formattedEndDate
                }
            })
            setMetrics(response.data)
        } catch (error) {
            console.error('Error fetching metrics:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate)
        if (selectedRange !== 'Custom') {
            setSelectedRange('Custom')
        }
    }

    const handleEndDateChange = (newDate) => {
        setEndDate(newDate)
        if (selectedRange !== 'Custom') {
            setSelectedRange('Custom')
        }
    }

    const updateDateRange = (range) => {
        setSelectedRange(range)
        switch (range) {
            case 'Today':
                setStartDate(dayjs().startOf('day'))
                setEndDate(dayjs().endOf('day'))
                break
            case 'Yesterday':
                setStartDate(dayjs().subtract(1, 'day').startOf('day'))
                setEndDate(dayjs().subtract(1, 'day').endOf('day'))
                break
            case 'This week':
                setStartDate(dayjs().startOf('week').add(1, 'day'))
                setEndDate(dayjs().endOf('week').add(1, 'day'))
                break
            case 'Last week':
                setStartDate(dayjs().subtract(1, 'week').startOf('week'))
                setEndDate(dayjs().subtract(1, 'week').endOf('week'))
                break
            case 'This month':
                setStartDate(dayjs().startOf('month'))
                setEndDate(dayjs().endOf('month'))
                break
            case 'Last month':
                setStartDate(dayjs().subtract(1, 'month').startOf('month'))
                setEndDate(dayjs().subtract(1, 'month').endOf('month'))
                break
            case 'This year':
                setStartDate(dayjs().startOf('year'))
                setEndDate(dayjs().endOf('year'))
                break
            case 'Maximum':
                setStartDate(null)
                setEndDate(null)
                break
            default:
                setStartDate(dayjs().startOf('day'))
                setEndDate(dayjs().endOf('day'))
        }
    }

    const calculatePercentageChange = (current, previous) => {
        if (!previous) return 0
        return ((current - previous) / previous * 100).toFixed(2)
    }

    if (loading) {
        return (
            <CenteredCircularProgress>
                <CircularProgress />
            </CenteredCircularProgress>
        )
    }

    return (
        <StyledContainer>
            <Typography variant="h4" gutterBottom>Dashboard</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            format="DD-MM-YYYY"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            format="DD-MM-YYYY"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Date Range</InputLabel>
                            <Select
                                value={selectedRange}
                                label="Date Range"
                                onChange={(e) => updateDateRange(e.target.value)}
                            >
                                <MenuItem value="Today">Today</MenuItem>
                                <MenuItem value="Yesterday">Yesterday</MenuItem>
                                <MenuItem value="This week">This Week</MenuItem>
                                <MenuItem value="Last week">Last Week</MenuItem>
                                <MenuItem value="This month">This Month</MenuItem>
                                <MenuItem value="Last month">Last Month</MenuItem>
                                <MenuItem value="This year">This Year</MenuItem>
                                <MenuItem value="Maximum">Maximum</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Product Sales</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product Name</TableCell>
                                            <TableCell>Total Sales</TableCell>
                                            <TableCell>Total Orders</TableCell>
                                            <TableCell>Total Units Sold</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {metrics.productSales?.map(product => (
                                            <TableRow key={product.id}>
                                                <TableCell>{product.name}</TableCell>
                                                <TableCell>{formatNumber(product.totalSales)} RON</TableCell>
                                                <TableCell>{formatNumber(product.orderCount)}</TableCell>
                                                <TableCell>{formatNumber(product.unitsSold)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Total Sales</Typography>
                            <Typography variant="h6">{formatNumber(metrics.totalSales)} RON</Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {calculatePercentageChange(metrics.totalSales, metrics.previousTotalSales)}% compared to previous period
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Orders</Typography>
                            <Typography variant="h6">{formatNumber(metrics.orderCount)}</Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {calculatePercentageChange(metrics.orderCount, metrics.previousOrderCount)}% compared to previous period
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Average Order Value</Typography>
                            <Typography variant="h6">{formatNumber(metrics.averageOrderValue)} RON</Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {calculatePercentageChange(metrics.averageOrderValue, metrics.previousAverageOrderValue)}% compared to previous period
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box display={'flex'} justifyContent={'space-between'} style={{ marginBottom: 40 }}>
                                <Typography variant="h5">Top Selling Product</Typography>
                                <Typography variant="h5">{metrics.topProduct?.name}</Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Typography variant="h6">{formatNumber(metrics.topProduct?.quantity)} units sold</Typography>
                                <Typography variant="h6">{formatNumber(metrics.topProduct?.orderCount)} orders</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Order Summary</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Status</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                            <TableCell align="right">Total Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {metrics.orderSummary?.map((summary, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{summary.status}</TableCell>
                                                <TableCell align="right">{summary.count}</TableCell>
                                                <TableCell align="right">{formatNumber(summary.total_amount)} RON</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </StyledContainer>
    )
}

export default Dashboard
import React, { useState } from 'react'
import {
    IconButton,
    Tooltip,
    Snackbar,
    Button
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CloseIcon from '@mui/icons-material/Close'

const CopyButton = ({ textToCopy }) => {
    const [open, setOpen] = useState(false)

    const handleCopyClick = () => {
        navigator.clipboard.writeText(textToCopy)
        setOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    const action = (
        <Button color="secondary" size="small" onClick={handleClose}>
            <CloseIcon />
        </Button>
    )

    return (
        <>
            <Tooltip title="Copy script integration code">
                <IconButton onClick={handleCopyClick} aria-label="copy">
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Copied to clipboard"
                action={action}
            />
        </>
    )
}

export default CopyButton

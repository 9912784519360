import React, { useState, useEffect } from 'react'
import axiosInstance from '../api/axiosInstance'
import { useParams, useNavigate } from 'react-router-dom'
import {
    Container,
    Grid,
    TextField,
    Button,
    Typography,
    Card,
    CardContent,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Autocomplete,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNotification } from '../context/NotificationContext'

const FormEditor = () => {
    const { formId, productId } = useParams()
    const navigate = useNavigate()
    const [form, setForm] = useState({
        fields: [],
        description: '',
        shipping_label: '',
        shipping_field: '',
        shipping_value: '',
        upsells: []
    })
    const [products, setProducts] = useState([])
    const { addNotification, addErrorNotifications } = useNotification()
    const fieldOptions = ['name', 'phone', 'county', 'city', 'address']

    useEffect(() => {
        if (formId) {
            const fetchForm = async () => {
                const response = await axiosInstance.get(`/forms/${formId}`)
                setForm(response.data)
            }
            fetchForm()
        }

        const fetchProducts = async () => {
            const response = await axiosInstance.get(`/products/dictionary`)
            setProducts(response.data)
        }
        fetchProducts()
    }, [formId])

    const handleFieldChange = (index, field, value) => {
        const newFields = [...form.fields]
        newFields[index][field] = value
        setForm({ ...form, fields: newFields })
    }

    const handleUpsellChange = (index, field, value) => {
        const newUpsells = [...form.upsells]
        newUpsells[index][field] = value
        setForm({ ...form, upsells: newUpsells })
    }

    const handleImageChange = (index, event) => {
        const file = event.target.files[0]

        const newUpsells = [...form.upsells]
        if (file) {
            newUpsells[index].image = file
            newUpsells[index].image_url = URL.createObjectURL(file)
        } else {
            newUpsells[index].image = null
            newUpsells[index].image_url = ''
        }

        setForm({ ...form, upsells: newUpsells })
    }

    const addField = () => {
        setForm({ ...form, fields: [...form.fields, { name: '', label: '', description: '', type: 'text' }] })
    }

    const removeField = (index) => {
        const newFields = form.fields.filter((_, i) => i !== index)
        setForm({ ...form, fields: newFields })
    }

    const addUpsell = () => {
        setForm({ ...form, upsells: [...form.upsells, { product_id: '', value: '', image: null, image_url: '', full_text: '' }] })
    }

    const removeUpsell = (index) => {
        const newUpsells = form.upsells.filter((_, i) => i !== index)
        setForm({ ...form, upsells: newUpsells })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('description', form.description)
        formData.append('shipping_label', form.shipping_label)
        formData.append('shipping_field', form.shipping_field)
        formData.append('shipping_value', form.shipping_value)
        formData.append('product_id', productId)

        form.fields.forEach((field, index) => {
            formData.append(`fields[${index}][name]`, field.name)
            formData.append(`fields[${index}][label]`, field.label)
            formData.append(`fields[${index}][description]`, field.description)
            formData.append(`fields[${index}][type]`, field.type)
        })

        form.upsells.forEach((upsell, index) => {
            formData.append(`upsells[${index}][product_id]`, upsell.product_id)
            formData.append(`upsells[${index}][value]`, upsell.value)
            formData.append(`upsells[${index}][full_text]`, upsell.full_text)
            if (upsell.image) {
                formData.append(`upsells[${index}][image]`, upsell.image)
            }

            if (upsell.image_url) {
                formData.append(`upsells[${index}][image_url]`, upsell.image_url)
            }
        })

        if (formId) {
            formData.append(`_method`, 'PUT')
            try {
                await axiosInstance.post(`/forms/${formId}`, formData)
                addNotification('Form updated successfully', 'success')
                navigate('/products')
            } catch (error) {
                addErrorNotifications(error.response?.data)
            }
        } else {
            try {
                await axiosInstance.post('/forms', formData)
                addNotification('Form created successfully', 'success')
                navigate('/products')
            } catch (error) {
                addErrorNotifications(error.response?.data)
            }
        }
    }

    const getAvailableOptions = (currentName) => {
        const selectedNames = form.fields.map(field => field.name)
        return fieldOptions.filter(option => !selectedNames.includes(option) || option === currentName)
    }

    return (
        <Container maxWidth="xxl">
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>{formId ? 'Edit Form' : 'Create Form'}</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Description"
                            value={form.description}
                            onChange={(e) => setForm({ ...form, description: e.target.value })}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Shipping Label"
                            value={form.shipping_label}
                            onChange={(e) => setForm({ ...form, shipping_label: e.target.value })}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Shipping Field"
                            value={form.shipping_field}
                            onChange={(e) => setForm({ ...form, shipping_field: e.target.value })}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Shipping Value"
                            value={form.shipping_value}
                            onChange={(e) => setForm({ ...form, shipping_value: e.target.value })}
                            fullWidth
                            margin="normal"
                            type="number"
                        />
                        <Grid spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Fields</Typography>
                            </Grid>
                            {form.fields.map((field, index) => (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Name</InputLabel>
                                            <Select
                                                value={field.name}
                                                onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    <em>Select a field</em>
                                                </MenuItem>
                                                {getAvailableOptions(field.name).map(option => (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            label="Label"
                                            value={field.label}
                                            onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            label="Description"
                                            value={field.description}
                                            onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Type</InputLabel>
                                            <Select
                                                value={field.type}
                                                onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                                            >
                                                <MenuItem value="text">Text</MenuItem>
                                                <MenuItem value="tel">Number</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <IconButton onClick={() => removeField(index)} aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={addField} style={{ marginTop: '10px' }}>
                                    Add Field
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Upsell Products</Typography>
                            </Grid>
                            {form.upsells?.map((upsell, index) => (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={12} sm={3}>
                                        <Autocomplete
                                            options={products}
                                            getOptionLabel={(option) => option.name}
                                            value={products.find(product => product.id === upsell.product_id) || null}
                                            onChange={(event, newValue) => {
                                                handleUpsellChange(index, 'product_id', newValue ? newValue.id : '')
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Search Product" margin="normal" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            label="Price"
                                            value={upsell.value}
                                            onChange={(e) => handleUpsellChange(index, 'value', e.target.value)}
                                            fullWidth
                                            margin="normal"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            label="Full Text"
                                            value={upsell.full_text}
                                            onChange={(e) => handleUpsellChange(index, 'full_text', e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id={`upsell-image-${index}`}
                                            type="file"
                                            onChange={(e) => handleImageChange(index, e)}
                                        />
                                        <label htmlFor={`upsell-image-${index}`}>
                                            <Button variant="contained" component="span" style={{ marginTop: '16px' }}>
                                                Upload Image
                                            </Button>
                                        </label>
                                        {upsell.image_url && (
                                            <div style={{ position: 'relative', display: 'inline-block', marginTop: '10px' }}>
                                                <img src={upsell.image_url} alt="option" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                <IconButton
                                                    onClick={() => handleImageChange(index, { target: { files: [null] } })}
                                                    style={{ position: 'absolute', top: '0', right: '0' }}
                                                >
                                                    <DeleteIcon style={{ color: 'red' }} />
                                                </IconButton>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <IconButton onClick={() => removeUpsell(index)} aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={addUpsell} style={{ marginTop: '10px' }}>
                                    Add Upsell
                                </Button>
                            </Grid>
                        </Grid>
                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                            Save
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}

export default FormEditor
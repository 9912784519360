import React, { useState, useEffect } from 'react'
import axiosInstance from '../api/axiosInstance'
import {
    Container,
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
    InputAdornment,
    Tooltip,
    IconButton,
    Box,
    Modal,
    Fade,
    Backdrop,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {useNotification} from "../context/NotificationContext";

const SettingsPage = () => {
    const [settings, setSettings] = useState({
        woocommerce_store_url: '',
        woocommerce_consumer_key: '',
        woocommerce_consumer_secret: '',
        facebook_pixel_script: '',
        thank_you_page: '',
        conversion_api_token: ''
    })
    const [apiKey, setApiKey] = useState('')
    const [showStars, setShowStars] = useState(true)
    const [syncing, setSyncing] = useState(false)
    const [emailRecipients, setEmailRecipients] = useState([''])
    const [open, setOpen] = useState(false)
    const { addNotification, addErrorNotifications } = useNotification()


    useEffect(() => {
        fetchSettings()
    }, [])

    const fetchSettings = async () => {
        const response = await axiosInstance.get('/settings', {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        })
        setSettings(response.data)
    }

    const handleChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await axiosInstance.post('/settings', settings, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        })
        addNotification('Settings saved successfully', 'success')
    }

    const handleShowApiKey = async () => {
        try {
            const response = await axiosInstance.get('/settings/api-key', {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            })
            setApiKey(response.data.api_key)
            setShowStars(false)
        } catch (error) {
            console.error('Error fetching API key:', error)
        }
    }

    const handleSyncOrders = async () => {
        setSyncing(true)
        try {
            await axiosInstance.post('/sync-woocommerce-orders', {}, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            })
            addNotification('Orders started to synchronize. You can leave this page', 'success')
        } catch (error) {
            addErrorNotifications('Failed to sync orders')
        } finally {
            setSyncing(false)
        }
    }

    const handleAddRecipient = () => {
        setEmailRecipients([...emailRecipients, ''])
    }

    const handleRemoveRecipient = (index) => {
        const updatedRecipients = [...emailRecipients]
        updatedRecipients.splice(index, 1)
        setEmailRecipients(updatedRecipients)
    }

    const handleRecipientChange = (index, event) => {
        const updatedRecipients = [...emailRecipients]
        updatedRecipients[index] = event.target.value
        setEmailRecipients(updatedRecipients)
    }

    const handleOpen = async () => {
        const response = await axiosInstance.get('/email-recipients', {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        })

        response.data.length && setEmailRecipients(response.data.map(email => email.email))

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSaveRecipients = async () => {
        try {
            await axiosInstance.post('/email-recipients', { emailRecipients }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            })
            addNotification('Email recipients saved successfully', 'success')
            handleClose()
        } catch (error) {
            addErrorNotifications('Failed to save email recipients')
        }
    }

    return (
        <Container maxWidth="sm">
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>Settings</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="WooCommerce Store URL"
                            name="woocommerce_store_url"
                            value={settings.woocommerce_store_url}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Thank you page (OPTIONAL)"
                            name="thank_you_page"
                            value={settings.thank_you_page}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            placeholder={`EX: ${settings.woocommerce_store_url ? settings.woocommerce_store_url : 'https://domain.ro/thank-you'}/thank-you`}
                        />
                        <TextField
                            label="WooCommerce Consumer Key"
                            name="woocommerce_consumer_key"
                            value={settings.woocommerce_consumer_key}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="WooCommerce Consumer Secret"
                            name="woocommerce_consumer_secret"
                            value={settings.woocommerce_consumer_secret}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Facebook Pixel ID"
                            name="facebook_pixel_script"
                            value={settings.facebook_pixel_script}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                        />

                        <TextField
                            label="Conversion API Token (OPTIONAL)"
                            name="conversion_api_token"
                            value={settings.conversion_api_token}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            label="API Key"
                            variant="outlined"
                            fullWidth
                            value={showStars ? '**********' : apiKey}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Show the API key">
                                            <IconButton onClick={handleShowApiKey}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: 20 }}>
                            Save Settings
                        </Button>
                        <Box>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSyncOrders}
                                disabled={syncing}
                                style={{ marginTop: '20px' }}
                            >
                                {syncing ? 'Syncing...' : 'Sync Orders from WooCommerce'}
                            </Button>
                        </Box>
                    </form>
                    <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginTop: '20px' }}>
                        Add Email Recipients
                    </Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <Box style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                backgroundColor: 'white',
                                border: '2px solid #000',
                                boxShadow: 24,
                                padding: '16px 32px 24px',
                            }}>
                                <Typography variant="h6" gutterBottom>Add Email Recipients</Typography>
                                {emailRecipients.map((email, index) => (
                                    <Box key={index} display="flex" alignItems="center" mb={2}>
                                        <TextField
                                            label="Email"
                                            value={typeof email === 'object' ? email.email : email}
                                            onChange={(e) => handleRecipientChange(index, e)}
                                            fullWidth
                                        />
                                        <IconButton onClick={handleAddRecipient}>
                                            <AddIcon />
                                        </IconButton>
                                        {emailRecipients.length > 1 && (
                                            <IconButton onClick={() => handleRemoveRecipient(index)}>
                                                <RemoveIcon />
                                            </IconButton>
                                        )}
                                    </Box>
                                ))}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveRecipients}
                                >
                                    Save Recipients
                                </Button>
                            </Box>
                        </Fade>
                    </Modal>
                </CardContent>
            </Card>
        </Container>
    )
}

export default SettingsPage

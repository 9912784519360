import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Divider,
    // Collapse,
} from '@mui/material'
import {
    Dashboard as DashboardIcon,
    Password as ChangePassword,
    Settings as SettingsIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material'
import { styled } from '@mui/system'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import InventoryIcon from '@mui/icons-material/Inventory'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

const drawerWidth = 240

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

const SidebarContainer = styled('div')(({ theme }) => ({
    display: 'flex',
}))

const DrawerStyled = styled(Drawer)(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

const NavItem = styled(NavLink)(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&.active > div': {
        backgroundColor: theme.palette.action.selected,
    },
}))

const Sidebar = () => {
    const [open, setOpen] = useState(false)

    const handleDrawerToggle = () => {
        setOpen(!open)
    }

    return (
        <SidebarContainer>
            <DrawerStyled variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerToggle}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <NavItem to="/metrics" activeClassName="active">
                        <ListItem button>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    </NavItem>

                    <NavItem to="/orders" activeClassName="active">
                        <ListItem button>
                            <ListItemIcon>
                                <ShoppingCartCheckoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Orders" />
                        </ListItem>
                    </NavItem>

                    <NavItem to="/partials" activeClassName="active">
                        <ListItem button>
                            <ListItemIcon>
                                <AddCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="Partials" />
                        </ListItem>
                    </NavItem>

                    <NavItem to="/products" activeClassName="active">
                        <ListItem button>
                            <ListItemIcon>
                                <InventoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Products" />
                        </ListItem>
                    </NavItem>

                    <NavItem to="/change-password" activeClassName="active">
                        <ListItem button>
                            <ListItemIcon>
                                <ChangePassword />
                            </ListItemIcon>
                            <ListItemText primary="Change Password" />
                        </ListItem>
                    </NavItem>

                    <NavItem to="/spends" activeClassName="active">
                        <ListItem button>
                            <ListItemIcon>
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add spends" />
                        </ListItem>
                    </NavItem>

                    <NavItem to="/settings" activeClassName="active">
                        <ListItem button>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                        </ListItem>
                    </NavItem>

                    {/*<ListItem button onClick={handleCollapseToggle}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <SettingsIcon />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Setari Generale" />*/}
                    {/*    {collapseOpen ? <ExpandLess /> : <ExpandMore />}*/}
                    {/*</ListItem>*/}
                    {/*<Collapse in={collapseOpen} timeout="auto" unmountOnExit>*/}
                    {/*    <List component="div" disablePadding>*/}
                    {/*        <NavItem to="/settings" activeClassName="active">*/}
                    {/*            <ListItem button sx={{ pl: 4 }}>*/}
                    {/*                <ListItemText primary="General" />*/}
                    {/*            </ListItem>*/}
                    {/*        </NavItem>*/}
                    {/*    </List>*/}
                    {/*</Collapse>*/}
                </List>
            </DrawerStyled>
        </SidebarContainer>
    )
}

export default Sidebar

import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter, TextField, Grid, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import axiosInstance from "../api/axiosInstance"
import { useNotification } from "../context/NotificationContext"
import { useNavigate, useLocation } from "react-router-dom"
import { formatNumber } from "../utils/Utils"
import queryString from 'query-string'
import { median, mean } from 'simple-statistics'

const RoasTable = () => {
    const { addErrorNotifications } = useNotification()
    const navigate = useNavigate()
    const location = useLocation()

    const parseQueryParams = () => {
        const queryParams = queryString.parse(location.search)
        return {
            start_date: queryParams.start_date || '',
            end_date: queryParams.end_date || ''
        }
    }

    const [roasData, setRoasData] = useState([])
    const [range, setRange] = useState(parseQueryParams())
    let spendFooter = { average: 0, median: 0 }
    let salesFooter = { average: 0, median: 0 }
    let roasFooter = { average: 0, median: 0 }

    const handleRangeChange = (e) => {
        const newRange = { ...range, [e.target.name]: e.target.value }
        setRange(newRange)

        const newQueryString = queryString.stringify(newRange)
        navigate(`?${newQueryString}`, { replace: true })
    }

    const fetchRoasData = async () => {
        try {
            const response = await axiosInstance.get('/roas-products', { params: range })
            setRoasData(response.data)
        } catch (error) {
            addErrorNotifications(error.response.data.errors)
        }
    }

    useEffect(() => {
        fetchRoasData()
    }, [range])

    useEffect(() => {
        setRange(parseQueryParams())
    }, [location.search])

    const handleEditClick = (product) => {
        navigate('/spends-form', { state: { ...product, date: range.start_date } })
    }

    const isSingleDateSelected = () => {
        return range.start_date && range.end_date && range.start_date === range.end_date
    }

    const calculateFooterValues = (data, key) => {
        const filteredValues = data
            .filter(item => item.spend > 0 && item.sales > 0)
            .map(item => Number(item[key]))

        return {
            average: filteredValues.length > 0 ? mean(filteredValues) : 0
        }
    }

    if (roasData.length > 0) {
        spendFooter = calculateFooterValues(roasData, 'spend')
        salesFooter = calculateFooterValues(roasData, 'sales')
        roasFooter = calculateFooterValues(roasData, 'roas')
    }

    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <TextField
                                    name="start_date"
                                    label="Start Date"
                                    type="date"
                                    value={range.start_date}
                                    onChange={handleRangeChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    name="end_date"
                                    label="End Date"
                                    type="date"
                                    value={range.end_date}
                                    onChange={handleRangeChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product ID</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Spend</TableCell>
                            <TableCell>Sales</TableCell>
                            <TableCell>ROAS</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roasData.map((product) => (
                            <TableRow key={product.product_id}>
                                <TableCell style={{ padding: 30 }}>{product.product_id}</TableCell>
                                <TableCell>{product.product_name}</TableCell>
                                <TableCell>{formatNumber(product.spend)} RON</TableCell>
                                <TableCell>{formatNumber(product.sales)} RON</TableCell>
                                <TableCell>{formatNumber(product.roas)}</TableCell>
                                <TableCell>
                                    <IconButton style={{ display: isSingleDateSelected() ? 'initial' : 'none' }} onClick={() => handleEditClick(product)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={2}>Average</TableCell>
                            <TableCell>{roasData.length > 0 ? `${formatNumber(spendFooter.average)} RON` : '-'}</TableCell>
                            <TableCell>{roasData.length > 0 ? `${formatNumber(salesFooter.average)} RON` : '-'}</TableCell>
                            <TableCell>{roasData.length > 0 ? formatNumber(roasFooter.average) : '-'}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Grid>
        </Grid>
    )
}

export default RoasTable

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import { Box, CssBaseline } from '@mui/material';

const drawerWidth = 240;

const Layout = ({ children }) => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const hideSidebarPaths = ['/login', '/register'];
    const shouldHideSidebar = hideSidebarPaths.includes(location.pathname);

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {!shouldHideSidebar && <Sidebar open={sidebarOpen} onToggle={handleSidebarToggle} />}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    ml: shouldHideSidebar ? 0 : `${sidebarOpen ? drawerWidth : '72px'}`,
                    transition: 'margin 0.3s',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
